import React, { useState, useRef } from "react";
import nycAgingLogo from "../images/nyc-aging.png";

// Updated styles to keep skip links invisible and ensure focus outlines on other elements
const styles = `
  /* General focus styles for interactive elements */
  a:focus:not(.skip-link),
  button:focus,
  input:focus,
  select:focus,
  textarea:focus {
    outline: 2px solid #007bff;
    outline-offset: 2px;
  }

  @media (forced-colors: active) {
    a:focus:not(.skip-link),
    button:focus,
    input:focus,
    select:focus,
    textarea:focus {
      outline: 3px solid HighlightText;
    }
  }

  /* Styles for skip links - kept invisible even when focused */
  .skip-link {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .skip-link:focus {
    outline: none;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .form-group {
    margin-bottom: 1rem;
  }
`;

const MessageComponent = () => {
  const [caregiverIdentifier, setCaregiverIdentifier] = useState("");
  const [error, setError] = useState("");
  const errorRef = useRef(null);

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,9}$/.test(value)) {
      setCaregiverIdentifier(value);
      if (value.length === 9) {
        setError("");
      } else {
        setError("Please enter a 9-digit number");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentUrl = new URL(window.location.href);
    const params = new URLSearchParams(currentUrl.search);
    params.append("token", caregiverIdentifier);
    const redirectUrl = `https://www.archangels-cii.me/anycarecounts-ny-2/?${params.toString()}`;
    window.location.href = redirectUrl;
  };

  return (
    <>
      <style>{styles}</style>
      <a href="#main-content" className="skip-link">Skip to main content</a>
      <a href="#caregiver-form" className="skip-link">Skip to form</a>

      <main id="main-content" role="main" aria-label="Caregiver Assessment Form" className="container mt-5 background-custom h-100">
        <div className="px-lg-5 px-md-2 px-sm-1">
          <header role="banner" className="text-center mb-4">
            <img
              src={nycAgingLogo}
              alt="NYC Department for the Aging Logo"
              className="img-fluid"
              style={{ maxWidth: '200px' }}
            />
          </header>

          <h1 className="custom-heading text-center">Complete the NY State caregiver assessment</h1>
          <h2 className="custom-heading text-center">
            (powered by the ARCHANGELS Caregiver Intensity Index)
          </h2>

          <form id="caregiver-form" onSubmit={handleSubmit} className="mt-4">
            <div className="form-group d-flex flex-column align-items-center">
              <label htmlFor="caregiverIdentifier" className="sr-only">
                Enter last 9 digits of client ID number (required)
              </label>
              <input
                type="text"
                className="form-control"
                id="caregiverIdentifier"
                name="caregiverIdentifier"
                value={caregiverIdentifier}
                required
                aria-required="true"
                placeholder="Enter last 9 digits of client ID number"
                aria-label="Enter last 9 digits of client ID number"
                aria-invalid={error ? "true" : "false"}
                aria-describedby={error ? "errorMessage" : undefined}
                style={{
                  backgroundColor: '#f0f0f0',
                  maxWidth: '300px',
                  width: '100%'
                }}
                onChange={handleInputChange}
                pattern="\d{9}"
                title="Please enter a 9-digit number"
              />
              {error && (
                <div
                  id="errorMessage"
                  className="mt-2 text-sm text-danger"
                  role="alert"
                  ref={errorRef}
                >
                  {error}
                </div>
              )}
            </div>
            <div className="text-center mt-3">
              <button
                type="submit"
                className="btn btn-lg"
                style={{
                  fontSize: "15px",
                  width: "170px",
                  height: "45px",
                  backgroundColor: "#131736",
                  color: "white",
                  border: "none"
                }}
                disabled={caregiverIdentifier.length !== 9}
                aria-disabled={caregiverIdentifier.length !== 9}
              >
                NEXT
              </button>
            </div>
          </form>
        </div>
      </main>

      <footer role="contentinfo" aria-label="Page footer" className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="custom-heading text-center">
                Thank you for your support in helping all caregivers get connected to resources based on their unique needs.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default MessageComponent;